/* Esdeveniments d'analytics / tag-manager demanats per Franquícies */
$(document).ready(function() {
    //window.dataLayer = window.dataLayer || [];

    //Esdeveniments genèrics

    $('body').on('submit', 'form', function(event){
        dataLayer.push({
            'event': 'eventoGA4',
            'event_name': 'form_submit',
            'form_id': $(this).attr('id'), //Atributo id de HTML del elemento DOM
            'form_name': $(this).attr('name'), //Atributo name de HTML del
            'form_destination': $(this).attr('action'), //URL a la que se envía el formulario.
            'form_submit_text': $(this).find('button').text() //Texto del botón para enviar (si se incluye).
        });
    });

    if ($('form').length > 0) {
        $('form').each(function(index){
            var firstTime = localStorage.getItem("first_time_form_" + $(this).attr('id'));
            if(!firstTime) {
                localStorage.setItem("first_time_form_" + $(this).attr('id'), "1");
                dataLayer.push({
                    'event': 'eventoGA4',
                    'event_name': 'form_start',
                    'form_id': $(this).attr('id'), //Atributo id de HTML del elemento DOM <form>
                    'form_name': $(this).attr('name'), //Atributo name de HTML del elemento DOM <form>.
                    'form_destination': $(this).attr('action') //URL a la que se envía el formulario.
                });
            }
        });
    }


    //Esdeveniments de franquícies

    $('body').on('click', '.site-menu a', function(){
        var section = $(this).text();

        dataLayer.push({
            'event': 'eventoGA4',
            'event_name': 'click_section',
            'section': section
        });
    });

    $('body').on('click', 'a.mesinfo', function(){
        dataLayer.push({
            'event': 'eventoGA4',
            'event_name': 'click_form_job'
        });
    });

    $('body').on('click', 'a.download-catalog', function(){
        dataLayer.push({
            'event': 'eventoGA4',
            'event_name': 'click_form_download'
        });
    });

    $('body').on('submit', '#getinfo-form form', function(event){
        console.log('getinfoformsubtmit');
    });

});
