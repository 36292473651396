// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

// function HCTest() {
//     'use strict';

//     var objDiv;
//     var strColor;
//     var objFlag;

//     // reference to img element used to check if images are disabled
//     objFlag = document.getElementById('flag');
//     if (objFlag === null) {
//         return;
//     }

//     // Create a test div
//     objDiv = document.createElement('div');

//     // Set its color style to something unusual
//     objDiv.style.color = 'rgb(31,41,59)';

//     // Attach to body so we can inspect it
//     document.body.appendChild(objDiv);

//     // Use standard means if available, otherwise use the IE methods

//     strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

//     // get rid of extra spaces in result
//     // strColor = strColor.replace(/ /g,"");

//     // Delete the test DIV

//     document.body.removeChild(objDiv);

//     // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
//     // Use .offsetwidth and .readyState (for IE) to check if images are enabled
//     // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

//     if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

//         var objHead = document.getElementsByTagName('head');
//         var objCSS = objHead[0].appendChild(document.createElement('link'));
//         objCSS.rel = 'stylesheet';
//         objCSS.href = '/media/css/alt.css';
//         // objCSS.type = 'text/css';
//     }
// }

// Safely initiate foundation now
// $(document).foundation();

// the rest of your code

// $(window).load(function() {
//     'use strict';

//     // accessible image sprites
//     HCTest();
// });


function marketing() {
  var lang = $('html').attr('lang');
  fbq('track', 'CompleteRegistration');
  if (lang == 'ca') {
    //ca
    gtag('event', 'conversion', {'send_to': 'GTM-KSLWGFK7/QcFHCJjH-ZMBEKehgfIC'});
  } else {
    //es
   gtag('event', 'conversion', {'send_to': 'GTM-KSLWGFK7/QcFHCJjH-ZMBEKehgfIC'});
  }

}


// mobile-nav
(function() {

    var mqm = window.matchMedia('(max-width: 46.25em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    var menu = button.nextElementSibling;

    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
        menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 620px)').matches) {
            menu.hidden = false;
        }
    });

    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // hamburger
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
    });

    // canviar de lloc la navegació (https://github.com/filamentgroup/AppendAround)
    $('.main-nav').appendAround();

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // Match Height
    $('.franq').matchHeight();

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#getinfo-form"])').click(function() {
      //$('a[href*="#"]:not([href="#"],[href="#s"],[href="#getinfor-form"])').click(function() {
      //$('a[href*="#"]:not([href="#"],[href="#s"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    // ScrollToFixed
    function mediaqueryresponse(mql){
     if (mql.matches){ // if media query matches
        $('.main-nav').scrollToFixed({
            marginTop: 20
        });
     }
     else {
        $('.main-nav').trigger('detach.ScrollToFixed');
     }
    }
    var mql = window.matchMedia('screen and (min-width: 64em)')
    mediaqueryresponse(mql) // call listener function explicitly at run time
    mql.addListener(mediaqueryresponse) // attach listener function to listen in on state changes

    // wrapper .picts-list
    $('.picts-list li').each(function() {
      $(this).contents().wrap('<span/>');
    });

    // pop-up form
    $('.popup-with-form').magnificPopup({
        tClose: 'Tancar (Esc)',
        type: 'inline',
        preloader: false,
        focus: '#nom',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            open: function() {
                var lang = $('html').attr('lang');
                var fran = $('html').attr('fran');

                if (lang == 'ca') var idioma = 'cat';
                else var idioma = 'cas';

                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'u1': idioma,
                    'u3': fran,
                    'send_to': 'DC-11798602/caprabo/reg_c0+standard'
                  });

                  gtag('event', 'conversion', {
                    'send_to': 'AW-10979276016/0gDFCMaz6t8DEPDpqfMo'
                })

                $('.noscript').empty();
                $('.noscript').append('<img src="https://ad.doubleclick.net/ddm/activity/src=11798602;type=caprabo;cat=reg_c0;u1='+idioma+';u3='+fran+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>');
                window.lintrk('track', { conversion_id: 9068796 });
                fbq('track', 'RegistroFranquicias');


            var recaptchaContainer = $('#recaptcha-container');

            $('.grecaptcha-badge').show();
            if (recaptchaContainer.children().length === 0) {
                $.getScript('https://www.google.com/recaptcha/api.js?render=6LetzgEqAAAAAIwxylNs_PttmjvE0yd-KqjfkCTh', function() {
                    grecaptcha.ready(function() {
                        grecaptcha.execute('6LetzgEqAAAAAIwxylNs_PttmjvE0yd-KqjfkCTh', { action: 'actionLogin' }).then(function(token) {
                            document.querySelector('#recaptchaResponse').value = token;
                        });
                    });
                });


            }

              },

            beforeOpen: function() {
                $('#getinfo-form .getinfo-success').hide();
                $('#getinfo-form .getinfo-error').hide();
                $('#getinfo-form form').show();
                if($(window).width() < 700) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#nom';
                }
            },

            close: function() {
                $('.grecaptcha-badge').hide();
            }


        }
    });
    $('#getinfo-form .close').click(function(){
        $.magnificPopup.close();
        // $('.grecaptcha-badge').hide();
        $('.grecaptcha-badge').css('visibility', 'hidden');
    });

    $('select#comarca').on('change', function(){
        var comarca = $(this).val();
        $('select#poblacio option').hide();
        $('select#poblacio option[data-comarca="'+comarca+'"]').show();
        if (comarca == '') {
            $('select#poblacio option').show();
        }
        if ($("select#poblacio option:selected").data('comarca') != comarca) {
            $("select#poblacio").val('');
        }
    });

    $('select#poblacio').on('change', function(){
        var comarca = $(this).find(':selected').data('comarca');
        $('select#comarca').val(comarca);
    });

    $.validator.setDefaults({
        errorElement: 'label',
        errorClass: 'error',
        errorPlacement: function(error, element) {
            if(element.parent('label').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        }
    });

    $('#getinfo-form form').validate({
        submitHandler: function(form){
            console.log('previ');
            var $form = $(form);
            $form.find('button').prop("disabled", true);
            var serializedData = $form.serialize();
            var url = $form.attr('action');
            $.ajax({
                type        : 'POST',
                url         : url,
                data        : serializedData,
                dataType    : 'json'
            })
            .done(function(data) {
                $('#getinfo-form form').hide();
                if (data.result) {
                    $('#getinfo-form .getinfo-success').show();

                    var lang = $('html').attr('lang');
                    var fran = $('html').attr('fran');

                    if (lang == 'ca') var idioma = 'cat';
                    else var idioma = 'cas';

                    gtag('event', 'conversion', {
                        'allow_custom_scripts': true,
                        'u1': idioma,
                        'u3': fran,
                        'send_to': 'DC-11798602/caprabo/typ_c0+standard'
                      });
                    $('.noscript').empty();
                    $('.noscript').append('<img src="https://ad.doubleclick.net/ddm/activity/src=11798602;type=caprabo;cat=typ_c0;u1='+idioma+';u3='+fran+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>');

                    dataLayer.push({
                        'event': 'eventoGA4',
                        'event_name': 'form_submit',
                        'form_id': $(this).attr('id'), //Atributo id de HTML del elemento DOM
                        'form_name': $(this).attr('name'), //Atributo name de HTML del
                        'form_destination': $(this).attr('action'), //URL a la que se envía el formulario.
                        'form_submit_text': $(this).find('button').text() //Texto del botón para enviar (si se incluye).
                    });


                    var local = $('select#local option:selected').val();
                    var invest = $('select#inversio option:selected').val();
                    if(invest == '0'){
                        invest = '0€';
                    } else if(invest == '1-50'){
                        invest = '1-50.000€';
                    } else if(invest == '50-100'){
                        invest = '50.001-100.000€';
                    } else if(invest == '100-200'){
                        invest = '100.001-200.000€';
                    } else if(invest == '200-more'){
                        invest = 'más de 200.000€';
                    }
                    var experiencia = $('select#experiencia option:selected').val();

                    dataLayer.push({
                        'event': 'eventoGA4',
                        'event_name': 'click_form_info',
                        'form_dispo': local, //Disponibilidad del local.
                        'form_invest': invest, //Capacidad de inversión
                        'form_dispo': experiencia, //Experiencia en el sector.
                    });

                    window.lintrk('track', { conversion_id: 9068860 });
                    fbq('track', 'CompleteRegistration');
                    gtag('event', 'conversion', {'send_to': 'GTM-KSLWGFK7/QcFHCJjH-ZMBEKehgfIC'});

                } else {
                    $('#getinfo-form .getinfo-error').show();
                }
            })
            .fail(function() {
                $('#getinfo-form form').hide();
                $('#getinfo-form .getinfo-error').show();
            })
            .always(function () {
                // reenable the inputs
                $form.find('button').prop("disabled", false);
            });
        }
    });
});
